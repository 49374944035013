/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-inner-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable no-empty */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  DatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "antd/es/select";
import Radio from "antd/es/radio";
import PublishIcon from "@material-ui/icons/Publish";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TimePicker from "antd/es/time-picker";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Mail from "../../images/TxtMailIcon.svg";
import search from "../../images/search.svg";
import Delete from "../../images/Delete_icon.png";
import SelectionIcon from "../../images/Select_icon.png";
import TimerIcon from "../../images/timesheet.svg";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import "./labelbox.css";
import moment from "moment/moment";



export default class Labelbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upload_model: false,
      gender: "M",
      open: false,
      value: null,
      selectedtime: props.value,
      selecteddate: props.value ? props.value : null,
      showCalendar: false,
      showPassword: false,
    };
  }

  datepickerChange(date) {
    console.log("this is the date picker+++", date)
    if (date === "Invalid Date") {
      this.props.invalidate && this.props.invalidate(date);
      console.log("if condition worked date picker")
    } else {
      console.log("else condition worked date picker")
      const datefmt = moment(date).format("YYYY-MM-DD");
     console.log("the formatted date is ", datefmt)
      this.props.changeData && this.props.changeData(datefmt);
    }
  }

  timepickerChange(time) {
    // var timeformat = dateFormat(time, "hh:MM:ss");
    this.props.changeData && this.props.changeData(time);
  }

  showhidePassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
formatDate(date){
  console.log("format date method triggers",date)
    if (!date) return new Date().toLocaleString();
  
    // // Get the timezone from browser using native methods
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const dateTmp = Date.parse(date.toLocaleString());
  
    // const localDate = formatInTimeZone(dateTmp, {
    //   timeZone: timezone,
    // });
  
    // return format(localDate, 'yyyy-MM-dd');
  };

  componentWillReceiveProps(props) {

    if (props.type === "datepicker") {
      //console.log("the props of the date picker====>", props)
         
      if (Number.isNaN(new Date(props.value).getTime())) {
      } else {
        // var datefmt = dateFormat(props.value && props.value, 'yyyy-mm-dd');
        // this.setState({ selecteddate: datefmt })
      }
    }
    if (props.gendervalue) {
      this.setState({ gender: props.gendervalue });
    }
  }

  renderinput(data) {

 

    if (data.type === "label") {
      return (
        <div className="labeltxt">
          {data.label && <div>{data.label}</div>}
          <div className={`${this.props.classes} label-read-only`}>
            {data.value || "---"}
          </div>
        </div>
      );
    } else if (data.type === "text") {
      return (
        <div className="formdiv inputlabel">
          {data.labelname && (
            <div className="labeltxt">
              <label>{data.labelname}</label>
              {data.required && <span className="asterisk">*</span>}
            </div>
          )}
          <div>
            <input
              className={`${data.error && "brdred"} brdrcls inputID`}
              value={this.props.value}
              maxLength={this.props.maxlength}
              type={
                data.password
                  ? this.state.showPassword
                    ? "text"
                    : "password"
                  : "text"
              }
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              onBlur={(e) =>
                this.props.SubmitData && this.props.SubmitData(e.target.value)
              }
              autocomplete={this.props.autocomplete ? "" : "new-password"}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              hidden={this.props.hidden}
              id={this.props.id && this.props.id}
              onKeyDown={(e) =>
                this.props.onKeyEnter &&
                (e.code === "Enter" || e.key === "Enter") &&
                this.props.onKeyEnter(e.target.value)
              }
            />
            {data.password && (
              <div
                onClick={() => this.showhidePassword()}
                className="password-icon"
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </div>
            )}
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    } else if (data.type === "number") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <input
              className={`${data.error && "brdred"} brdrcls inputID`}
              min="0"
              value={this.props.value}
              type="number"
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              // onKeyDown={(e) =>
              //   (e.key === "e" || e.key === "+" || e.key === "-") &&
              //   e.preventDefault()
              // }
              disabled={this.props.disabled}
              onKeyDown={(e) =>
                this.props.onKeyEnter &&
                (e.code === "Enter" || e.key === "Enter") &&
                this.props.onKeyEnter(e.target.value)
              }
            />
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    } else if (data.type === "textarea") {
      return (
        <div className="formdiv">
          {data.labelname && (
            <label className="labeltxt">{data.labelname}</label>
          )}
          <div>
            <textarea
              className={`${data.error && "brdred"} txtarea`}
              rows={this.props.rows || 1}
              // maxRows={this.props.maxRows}
              cols="50"
              value={this.props.value}
              placeholder={this.props.placeholder}
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              disabled={this.props.disabled}
              onKeyDown={(e) =>
                this.props.onKeyEnter &&
                (e.code === "Enter" || e.key === "Enter") &&
                this.props.onKeyEnter(e.target.value)
              }
            />
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    } else if (data.type === "checkbox") {
      return (
        <div className="check">
          <input
            type="checkbox"
            checked={this.props.checked}
            onChange={(e) =>
              this.props.changeData && this.props.changeData(e.target.checked)
            }
            // inputProps={{ 'aria-label': 'primary checkbox' }}
            name={this.props.name && this.props.name}
            classname={this.props.classes && this.props.classes}
            disabled={!!this.props.disabled}
            id={this.props.id || "labelbox-check"}
            required={!!this.props.required}
            size={this.props.size && this.props.size}
            ref={this.props.checkRef}
          />
          {this.props.label && (
            <label for={this.props.id || "labelbox-check"}>
              {this.props.label}
            </label>
          )}
          {/* <label>{this.props.label && this.props.label}</label> */}
        </div>
      );
    } else if (data.type === "radio") {
      return (
        <div className="formdiv">
          <div>
            <Radio.Group
              className={`${data.error && "brdred"}`}
              options={this.props.options && this.props.options}
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              value={this.props.value && this.props.value}
              optionType="default"
              buttonStyle="solid"
              size={this.props.size && this.props.size}
              disabled={this.props.disabled && this.props.disabled}
              onKeyDown={(e) =>
                this.props.onKeyEnter &&
                (e.code === "Enter" || e.key === "Enter" || e.keyCode === 13) &&
                this.props.onKeyEnter(e.target.value)
              }
            />
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    } 
    else if (data.type === "datepicker") {

      return (
        <div className="formdiv">

          {data.labelname && (
            <div className="labeltxt">
              <label>{data.labelname}</label>
              {data.required && <span className="asterisk">*</span>}
            </div>
          )}
          <div
            className={`${data.error && "datePickerbrdred"} ${
              this.props.className
            }`}
          >
            {this.props.customDateInput && (
              <input
                type="text"
                value={this.props.customDateInput || ""}
                className="customDateInput"
              />
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                placeholder={this.props.placeholder}
                disableToolbar={
                  this.props.disableToolbar && this.props.disableToolbar
                }
                autoOk
                disabled={this.props.disabled}
                views={this.props.view && this.props.view}
                clearable={false}
                disableUnderline
                disableFuture={
                  this.props.disableFuture ? this.props.disableFuture : false
                }
                disablePast={this.props.disablePast && this.props.disablePast}
                minDate={this.props.minDate && this.props.minDate}
                maxDate={this.props.maxDate && this.props.maxDate}
                minDateMessage={
                  this.props.minDateMessage && this.props.minDateMessage
                }
                maxDateMessage={
                  this.props.maxDateMessage && this.props.maxDateMessage
                }
                inputVariant="outlined"
                format={
                  this.props.format === "MMM-yyyy" ? "MMM-yyyy" : "MMM dd yyyy"
                }
                margin="normal"
                id="date-picker-inline"
                // value={this.state.selecteddate}
                InputProps={{ readOnly: true }}
                // value={this.props.value === "" ? null : this.props.value}
               
                    //  value={this.props.value === "" ? null : 'Tue Apr 30 2019 20:00:00 GMT-0400 (Eastern Daylight Time)'}
               
                    value={this.props.value === "" ? null : this.props.value}
                    onChange={(...date) => this.datepickerChange(...date)}
                onKeyDown={(e) =>
                  this.props.onKeyEnter &&
                  (e.key === "Enter" || e.key === "Enter") &&
                  this.props.onKeyEnter(e.target.value)
                }
                shouldDisableDate={(date) =>
                  this.props.disableWeekends
                    ? [0, 6].includes(date.getDay())
                    : false
                }
              />
            </MuiPickersUtilsProvider>

            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    } 
    else if (data.type === "timepicker") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            {/* <TimePicker value={this.props.value} onChange={(time)=>this.onChange(time)} /> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                margin="normal"
                inputVariant="outlined"
                id="time-picker"
                value={this.props.value || new Date()}
                onChange={(time) => this.timepickerChange(time)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                minTime={this.props.minTime && this.props.minTime}
                maxTime={this.props.maxTime && this.props.maxTime}
                InputProps={{ readOnly: true }}
                keyboardIcon={
                  <img
                    src={TimerIcon}
                    alt=""
                    className="labelboxTimePicker"
                    minTime={this.props.minTime && this.props.minTime}
                    maxTime={this.props.maxTime && this.props.maxTime}
                  />
                }
                disabled={this.props.disabled}
              />
            </MuiPickersUtilsProvider>
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          </div>
        </div>
      );
    }
     else if (data.type === "select") {
      const { Option } = Select;

      let optionValue = null;
      data.dropdown &&
        data.dropdown.map((value) => {
          if ((value.id ?? value[data.opt_id]) === data.value) {
            optionValue = value.value || value[data.opt_value];
          }
          return true;
        });

      let selectValue = [];

      if (data.value && this.props.mode === "multiple") {
        selectValue = data.value;
      } else if (this.props.mode === "multiple" && data.value === "") {
        selectValue = [];
      } else {
        selectValue = optionValue;
      }

      return (
        <div className="formdiv">
          {data.labelname && (
            <div className="labeltxt">
              <label>{data.labelname}</label>
              {data.required && <span className="asterisk">*</span>}
            </div>
          )}
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            disabled={this.props.disabled && true}
            className={`${data.error && "selectbrdred brdnone"} ${
              this.props.mode !== "multiple" && "selectAdjustHeight"
            } selectbox`}
            showSearch
            mode={this.props.mode ? this.props.mode : false}
            value={selectValue || this.props.placeholder}
            suffixIcon={<img src={SelectionIcon} alt="" />}
            // showArrow={true}
            placeholder={this.props.placeholder}
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) =>
              this.props.changeData && this.props.changeData(value)
            }
            onSearch={(value) =>
              this.props.searchData && this.props.searchData(value)
            }
            onBlur={() => this.props.blurData && this.props.blurData()}
            // onKeyDown={(e) =>
            //   this.props.onKeyEnter &&
            //   (e.key === "Enter" || e.key === "Enter" || e.keyCode === 13) &&
            //   this.props.onKeyEnter(e.target.value)
            // }
          >
            {data.dropdown && data.dropdown.length > 0
              ? data.dropdown.map((item, index) => {
                  if (item.value || item[data.opt_value]) {
                    if (this.props.mode === "multiple") {
                      return (
                        <Option
                          key={index}
                          disabled={item.disable}
                          value={item.value || item[data.opt_value]}
                        >
                          {item.value || item[data.opt_value]}
                        </Option>
                      );
                    }
                    if (this.props.stringvalue) {
                      return (
                        <Option
                          key={index}
                          disabled={item.disable}
                          value={item.value || item[data.opt_value]}
                        >
                          {item.value || item[data.opt_value]}
                        </Option>
                      );
                    }

                    return (
                      <Option
                        key={index}
                        disabled={item.disable}
                        value={item.id ?? item[data.opt_id]}
                      >
                        {item.value || item[data.opt_value]}
                      </Option>
                    );
                  }
                  return true;
                })
              : null}
          </Select>
          <div className="Errormsg">
            <div>{data.error && "Selection Required"}</div>
          </div>
        </div>
      );
    } else if (data.type === "mailbox") {
      return (
        <div className="formdiv">
          {data.labelname && (
            <label className="labeltxt">{data.labelname}</label>
          )}
          <TextField
            className={`${data.error && "mailbrdred"}`}
            required={this.props.required && this.props.required}
            variant="outlined"
            margin="normal"
            fullWidth
            // placeholder={"Email Address"}
            name="text"
            value={this.props.value && this.props.value}
            type="text"
            autoComplete={false}
            disabled={!!this.props.disabled}
            onChange={(e) =>
              this.props.changeData && this.props.changeData(e.target.value)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={Mail} alt="" />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) =>
              this.props.onKeyEnter &&
              (e.code === "Enter" || e.key === "Enter") &&
              this.props.onKeyEnter(e.target.value)
            }
          />
          <div className="Errormsg">
            <div>{data.error && data.errmsg}</div>
          </div>
        </div>
      );
    } else if (data.type === "searchbox") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <TextField
            required={this.props.required && this.props.required}
            variant="outlined"
            margin="normal"
            fullWidth
            placeholder="Search here"
            name="text"
            value={this.props.value && this.props.value}
            type="text"
            autoComplete={false}
            onChange={(e) =>
              this.props.changeData && this.props.changeData(e.target.value)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img src={search} alt="" />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) =>
              this.props.onKeyEnter &&
              (e.code === "Enter" || e.key === "Enter") &&
              this.props.onKeyEnter(e.target.value)
            }
          />
          <div className="Errormsg">
            <div>{data.error && data.errmsg}</div>
          </div>
        </div>
      );
    } else if (data.type === "upload") {
      /**
       * @function onFileView to view the value
       * @param {string} url passing the url
       */
      function onFileView(url) {
        window.open(
          `${url}`,
          "Popup",
          "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
        );
      }

      return (
        <div className="inputlabel" style={{ lineHeight: 0 }}>
          {data.labelname && (
            <div className="labeltxt">
              <label>{data.labelname}</label>
              {data.required && <span className="asterisk">*</span>}
            </div>
          )}
          <div className={`${data.error && "brdred"} upload`}>
            <div className="upload-btn-wrapper" style={{ width: "100%" }}>
              {/* <Button icon={<img src={FileUpload} style={{ width: '1.3rem' }} />} text="Upload" /> */}
              <input
                id="finlo-upload"
                type="file"
                multiple
                accept="image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                // style={{ fontSize: 12 }}
                // value={this.props.value && this.props.value === [] && this.props.value ? this.props.value : ''}
                onChange={(e) =>
                  this.props.changeData && this.props.changeData(e.target.files)
                }
                disabled={this.props.disabled}
              />
            </div>
            {this.props.view_file &&
              this.props.view_file !== "null" &&
              this.props.view_file.length > 0 && (
                <>
                  <hr />
                  <div style={{ display: "flex", marginTop: -6 }}>
                    <div
                      style={{ width: "100%", cursor: "pointer" }}
                      onClick={() => onFileView(this.props.view_file)}
                    >
                      {this.props.view_file && this.props.view_file.length > 0
                        ? this.props.view_file.substr(35, 16).length > 15
                          ? `${this.props.view_file.substr(35, 16)}..`
                          : this.props.view_file.substr(35, 16)
                        : ""}
                    </div>
                    <img
                      src={Delete}
                      alt=""
                      onClick={() => this.setState({ upload_model: true })}
                      style={{
                        width: "20px",
                        cursor: "pointer",
                        padding: "3px",
                      }}
                    />
                  </div>
                </>
              )}
          </div>
          <div className="Errormsg">
            <div>{data.error && data.errmsg}</div>
          </div>
        </div>
      );
    } else if (data.type === "timeselector") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <TimePicker
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e)
              }
              value={data.value ? moment(data.value, "hh:mm") : ""}
              autoComplete={"off"}
              minuteStep={5}
              defaultOpenValue={moment("00:00", "hh:mm")}
              disabledHours={() => data.disabledHours && data.disabledHours}
              disabledMinutes={() =>
                data.disabledMinutes && data.disabledMinutes
              }
              format={"HH:mm"}
              allowClear={false}
              disabled={!!this.props.disabled}
              // use12Hours
            />
            {
              <div className="Errormsg">
                <div>{data.error && data.errmsg}</div>
              </div>
            }
          </div>
        </div>
      );
    }
  }

  render() {
    const labelcss = require("./labelbox.css");
    return (
      <div className="custom_labelbox">{this.renderinput(this.props)}</div>
    );
  }
}
