import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Modal, Button, Labelbox } from "../components";
import {INVOICE_ACTION } from "../redux/action"
import { Toaster } from "../utils/common";
import { useDispatch, useSelector } from "react-redux";
const PaymentStatusModal = ({ open, handleClose, invoice }) => {
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [remainingTotal, setRemainingTotal] = useState(Math.max((invoice?.total - invoice.amount_paid),0).toFixed(2) || 0);
  

  useEffect(() => {
    // Reset modal fields on open
    if (open) {
      setPaymentStatus("");
      setPaymentMethod("");
      setAmountPaid("");
      setRemainingTotal(remainingTotal);
    }
  }, [open, invoice]);
const dispatch = useDispatch();
  const handlePaymentStatusChange = (value) => {
    setPaymentStatus(value);
    // Reset amountPaid if not "Partially Paid"
    if (value !== "Partially Paid") {
      if (value === "Pending")
      {setAmountPaid("0");
      setRemainingTotal(remainingTotal);}
      else
      {
        setAmountPaid(invoice.total)
        setRemainingTotal("0");
      }
    }
  };

  const handleAmountChange = (value) => {
    setAmountPaid(value);
    // Update remaining total
    // if (!isNaN(value) && value <= invoice.total) {
    //   const remaining = invoice.total - parseFloat(value);
    //   setRemainingTotal(remaining.toFixed(2));
    // }
  };

  const handleUpdate = () => {
    // Call the API to update invoice status and method
    const updatedata =  INVOICE_ACTION.InvoicePaymentUpdate(invoice.invoice_no,paymentStatus, paymentMethod, amountPaid, remainingTotal);
    if(updatedata)
    {
      console.log("SUCCESS DATA: ", updatedata);
      handleClose();
      window.location.reload();
      Toaster.success("Invoice Updated Successfully!");
        
    }
    else
    {
      Toaster.error("Error Updating Invoice!");
         
    }
    
  };

  return (
    <Modal open={open} title="Update Payment Status" handleClose={handleClose}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ fontSize: "18px" }}>
            <p><strong>Invoice No: {invoice.invoice_no}</strong></p>
            <p><strong>Total Amount: ${invoice.total}</strong></p>
          </div>
          <Labelbox
            type="select"
            labelname="Payment Status"
            value={paymentStatus}
            dropdown={[
              { id: "Pending", value: "Pending" },
              { id: "Partially Paid", value: "Partially Paid" },
              { id: "Paid", value: "Paid" },
            ]}
            changeData={(value) => handlePaymentStatusChange(value)}
          />
        </Grid>

        {paymentStatus === "Partially Paid" && (
          <Grid item xs={12}>
            <Labelbox
              type="text"
              labelname="Amount Paid"
              value={amountPaid}
              changeData={(value) => handleAmountChange(value)}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Labelbox
            type="select"
            labelname="Payment Method"
            value={paymentMethod}
            dropdown={[
              { id: "Zelle", value: "Zelle" },
              { id: "Check", value: "Check" },
              { id: "ACH", value: "ACH" },
              { id: "Swipepay", value: "Swipepay" },
              { id: "none", value: "Not Yet paid" },
            ]}
            changeData={(value) => setPaymentMethod(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <p><strong>Due Amount: ${remainingTotal}</strong></p>
        </Grid>

        <Grid item xs={12}>
          <div className="hbox">
            <Button text="Update" handleClick={handleUpdate} />
            <Button text="Cancel" handleClick={handleClose} />
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PaymentStatusModal;
