import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useLayoutEffect,
} from "react";
import {
  Uploadcloud,
  Payment,
  Invoice,
  Handshake,
  Client,
  RegisterClient,
  Book
} from "../../images";
import { Table, Modal, Labelbox, Button, FrameBox } from "../../components";
import "./dashboard.scss";
import { useNavigate } from "react-router-dom";
import {
  dateTimeFormatter,
  Toaster,
  NotificationContext,
} from "../../utils/common";
import { CLIENT_ACTION ,EMPLOYEE_ACTION} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import Empty from "antd/es/empty";
// import { getUserFiles, deleteFile } from "../../redux/action/documentAction";

const ClientCard = [
  // {
  //   name: "Services We Offer",
  //   Icon: TaxService,
  //   count: "0",
  //   className: "card-0",
  //   path: "/finlo/service_offers",
  // },
  {
    name: "Upload Documents",
    Icon: Uploadcloud,
    count: "0",
    path: "/finlo/documents",
    className: "card-0",
  },
  {
    name: "Make Payment",
    Icon: Payment,
    count: "0",
    path: "/finlo/payment",
    className: "card-1",
  },
  {
    name: "Contracts",
    Icon: Handshake,
    count: "0",
    path: "/finlo/contract_proposal",
    className: "card-2",
    stateMode: "contract",
    adminCardType: true,
  },
  {
    name: "Invoices",
    Icon: Invoice,
    count: "0",
    path: "/finlo/payment",
    className: "card-3",
    stateMode: "invoice",
    adminCardType: true,
  },
];

const AdminCard = [
  {
    name: "Clients",
    Icon: Client,
    count: "0",
    path: "/finlo/client",
    className: "card-0",
  },
  {
    name: "Documents",
    Icon: Book,
    count: "0",
    path: "/finlo/documents",
    className: "card-1",
  },
  {
    name: "Contracts",
    Icon: Handshake,
    count: "0",
    path: "/finlo/invoice_contract",
    className: "card-2",
    stateMode: "contract",
  },
  {
    name: "Invoices",
    Icon: Invoice,
    count: "0",
    path: "/finlo/invoice_contract",
    className: "card-3",
    stateMode: "invoice",
  },
  {
    name: "Prospects",
    Icon: RegisterClient,
    count: "0",
    cursor: true,
    className: "card-4",
    handleClick: true,
  },
];

const ManagerCard = [
  {
    name: "Clients",
    Icon: Client,
    count: "0",
    path: "/finlo/client",
    className: "card-0",
  },
  {
    name: "Documents",
    Icon: Book,
    count: "0",
    path: "/finlo/documents",
    className: "card-1",
  },
  {
    name: "Contracts",
    Icon: Handshake,
    count: "0",
    path: "/finlo/invoice_contract",
    className: "card-2",
    stateMode: "contract",
  },
  {
    name: "Invoices",
    Icon: Invoice,
    count: "0",
    path: "/finlo/invoice_contract",
    className: "card-3",
    stateMode: "invoice",
  },
  {
    name: "Prospects",
    Icon: RegisterClient,
    count: "0",
    cursor: true,
    className: "card-4",
    handleClick: true,
  },

];
const EmployeeCard = [
  {
    name: "Clients",
    Icon: Client,
    count: "0",
    path: "/finlo/client",
    className: "card-0",
  },
  {
    name: "Documents",
    Icon: Book,
    count: "0",
    path: "/finlo/documents",
    className: "card-1",
  },
  {
    name: "Contracts",
    Icon: Handshake,
    count: "0",
    path: "/finlo/invoice_contract",
    className: "card-2",
    stateMode: "contract",
  },
  {
    name: "Invoices",
    Icon: Invoice,
    count: "0",
    path: "/finlo/invoice_contract",
    className: "card-3",
    stateMode: "invoice",
  },

];

// const clientHeader = [
//   { id: "1", label: "File" },
//   { id: "3", label: "File type" },
//   { id: "4", label: "File size" },
//   { id: "2", label: "Uploaded Date & Time" },
// ];

// const adminHeader = [
//   { id: "1", label: "Client" },
//   { id: "2", label: "File" },
//   { id: "4", label: "File type" },
//   { id: "5", label: "File size" },
//   { id: "3", label: "Uploaded Date & Time" },
//   { id: "6", label: "Action" },
// ];

const activityHeader = [
  { id: "1", label: "S.No." },
  { id: "2", label: "Activity" },
  { id: "3", label: "Created" },
];
const Dashboard = () => {
  const dispatch = useDispatch();
  const { notifications } = useContext(NotificationContext);

  // const { userFiles } = useSelector((state) => state.document);

  const navigate = useNavigate();
  const USER_ID = localStorage.getItem("user_id");
  const USER_TYPE = localStorage.getItem("user_role");
  //console.log("the user role is ===>",USER_TYPE)
  const card = USER_TYPE === "client" ? ClientCard : USER_TYPE === "admin" ?  AdminCard : USER_TYPE === "manager" ?  ManagerCard : EmployeeCard ;
  const { dashboardCardDetails, registeredClients } = useSelector(
    (state) => state.client
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedClientEmployees,setSelectedClientEmployees ] = useState({});
  const userFilePayload = USER_TYPE === "client" ? { user_id: USER_ID } : {};



  useLayoutEffect(() => {
    dispatch(CLIENT_ACTION.getDashboardCardDetails(userFilePayload));
  }, []);

  useEffect(() => {
    // dispatch(getUserFiles(userFilePayload));
    dispatch(CLIENT_ACTION.getRegisteredClients());
  }, [USER_ID]);



  /////************ API DATA after clarity should get rid of unwanted actions************////////////
  useEffect(() => {
    dispatch(EMPLOYEE_ACTION.getAllEmployees());
    dispatch(EMPLOYEE_ACTION.getActiveEmployees());
    dispatch(EMPLOYEE_ACTION.getActiveManagers());
  }, [dispatch]);

  const {
    employee: { activeEmployees, allEmployees, activeManagers },
    master,
  } = useSelector((state) => state);
//console.log("all the employees are", allEmployees)

const employeesList = useMemo(() => {
  return (
    activeEmployees &&
    activeEmployees
      .filter((data) => data.role_id === 3) // Only employees with role_id === 3
      .map((data) => ({
        id: data.employee_id,
        value: `${data.first_name} ${data.last_name} - ${data.email}`,
      }))
  );
}, [activeEmployees]);

const managersList = useMemo(() => {
  return (
    activeManagers &&
    activeManagers
      .filter((data) => data.role_id === 2) // Only managers with role_id === 2
      .map((data) => ({
        id: data.employee_id,
        value: `${data.first_name} ${data.last_name} - ${data.email}`,
      }))
  );
}, [activeManagers]);

const chooseEmployee = (employeeId, clientId) => {
  setSelectedClientEmployees((prev) => ({
    ...prev,
    [clientId]: { ...prev[clientId], employee_id: employeeId }, // Update employee_id
  }));
};

const chooseManager = (managerId, clientId) => {
  setSelectedClientEmployees((prev) => ({
    ...prev,
    [clientId]: { ...prev[clientId], manager_id: managerId }, // Update manager_id
  }));
};
  // const userFilesTableData = useMemo(() => {
  //   return userFiles.map((data, index) => {
  //     if (USER_TYPE === "admin" || USER_TYPE === "employee") {
  //       return {
  //         client: (
  //           <Link
  //             to="/finlo/documents"
  //             state={{
  //               clientDetails: {
  //                 USER_ID: data.client_id,
  //                 s3Location: data.s3_root_folder,
  //               },
  //             }}
  //             className="link"
  //           >
  //             {`${data.first_name} ${data.last_name} `}
  //           </Link>
  //         ),
  //         file: data.document_name,
  //         type: data.document_extension,
  //         size: `${Math.round(data.document_size / 1024)} kb`,
  //         updated: dateTimeFormatter(data.createdAt),
  //       };
  //     } else {
  //       return {
  //         file: data.document_name,
  //         type: data.document_extension,
  //         size: `${Math.round(data.document_size / 1024)} kb`,
  //         updated: dateTimeFormatter(data.createdAt),
  //       };
  //     }
  //   });
  // }, [userFiles]);

  // const onItemAction = async (type, actionData, index) => {
  //   // for delete the row
  //   const userData = userFiles[index];
  //   try {
  //     const response = await deleteFile({
  //       client_document_id: userData.client_document_id,
  //       folder_name: userData.folder_name,
  //       file_name: userData.document_name,
  //     });
  //     dispatch(getUserFiles(userFilePayload));
  //     if (response.status === 200 && response.data.status === 1) {
  //       Toaster.success("Document deleted successfully");
  //     } else {
  //       Toaster.error("Error occurred while deleting the document");
  //     }
  //   } catch (error) {
  //     Toaster.error("Error occurred while deleting the document");
  //   }
  // };

  const handleClick = (card) => {
    if (card.name === "Prospects") {
      setShowModal(true);
    }
  };

  const handleCheck = (selectedItem) => {
    const tempClientList = JSON.parse(JSON.stringify(selectedClient));
    const isExist = tempClientList.findIndex(
      (item) => item.client_id === selectedItem.client_id
    );
    if (isExist !== -1) tempClientList.splice(isExist, 1);
    else
      tempClientList.push({
        client_id: selectedItem.client_id,
        email: selectedItem.email,
        first_name: selectedItem.first_name,
      });
    setSelectedClient([...tempClientList]);
  };

  const ActionClick = async (actionType) => {
    if (selectedClient.length === 0) {
      Toaster.warning("Select at least one registered client");
      return;
    }
    if (selectedClient.length > 0 && Object.entries(selectedClientEmployees).length === 0) {
      Toaster.warning("Assign both manager and employee to the selected client");
      return;
    }
  
    const updatedClientWithEmployeeDetails = selectedClient.map((client) => {
      const clientAssignment = selectedClientEmployees[client.client_id];
      if (clientAssignment) {
        return {
          ...client,
          sales: clientAssignment.manager_id, // Assign sales_value to the manager's ID
          employee_id: clientAssignment.employee_id, // Assign employee_id
        };
      }
      return client;
    });
  
    const payloadData = {
      clients_details: updatedClientWithEmployeeDetails,
      status: actionType === "approve" ? 1 : 2,
    };
  
    try {
      const response = await CLIENT_ACTION.updateRegisteredClientStatus(payloadData);
      dispatch(CLIENT_ACTION.getDashboardCardDetails(userFilePayload));
      dispatch(CLIENT_ACTION.getRegisteredClients());
      if (response.status === 200) {
        Toaster.success("Registered client status updated successfully");
        setShowModal(false);
      } else {
        Toaster.error("Error occurred while updating client data");
      }
    } catch (error) {
      Toaster.error("Error occurred while updating client data");
    }
  };

  const notificationData = useMemo(() => {
    return (
      notifications.map(({ message, createdAt }, index) => {
        return {
          s_no: index + 1,
          message,
          createdAt: dateTimeFormatter(createdAt),
        };
      }) || []
    );
  }, [notifications]);



  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-card-wrapper">
        {card.map((data) => {
          return (
            <div className="card-items">
              <div
                className={`dashcard ${data.className} ${
                  data.path || data.cursor ? "" : "cursor_default"
                }`}
                onClick={() => {
                  if (data.path)
                    navigate(data.path, {
                      state: data.stateMode && {
                        mode: data.stateMode,
                      },
                    });
                  if (data.handleClick) handleClick(data);
                }}
              >
                <div className="circle-ripple" />

                {USER_TYPE !== "client" || data.adminCardType ? (
                  <>
                    <div className="Dashboard_card_title">
                      <div className="card-title">{data.name}</div>
                    </div>
                    <div className="card_content">
                      <div className="align_card_text">
                        <div className="icon_main_div">
                          <div className="icon_image_size">
                            <img src={data.Icon} alt="" />
                          </div>
                        </div>

                        <div className="number_count">
                          <div className="count">
                            {dashboardCardDetails[data.name]}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="client-card">
                    <div className="client-card-img">
                      <img src={data.Icon} alt="client-card-icon" />
                    </div>
                    <div className="card-title">{data.name}</div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {/* <Table
        title="Recent Uploads"
        header={
          USER_TYPE === "admin" || USER_TYPE === "employee"
            ? adminHeader
            : clientHeader
        }
        data={userFilesTableData}
        actions={["delete"]}
        onActionClick={onItemAction}
        search
      /> */}
      <Table
        title="Recent Activities"
        header={activityHeader}
        data={notificationData}
      />
      <Modal
        title="Prospects"
        open={showModal}
        handleClose={() => setShowModal(false)}
        className="register-modal"
      >
        {!!registeredClients?.length ? (
          <FrameBox title="List of prospects" variant="small">
            <div className="client-table-wrapper">
              <div className="clients-box">
                <div className="client-header">
                  <div>Client Name</div>
                  <div>Email Id</div>
                       <div>Assign to Manager</div>
                       <div>Assign to Employee</div>
                </div>
                {registeredClients.map((client, index) => (
  <div className="check-gap" key={index}>
    <Labelbox
      type="checkbox"
      checked={selectedClient.some(
        (item) => item.client_id === client.client_id
      )}
      label={`${client.first_name} ${client.last_name}`}
      changeData={() => handleCheck(client)}
    />
    <Labelbox type="label" value={client.email} />
    <Labelbox
      value={selectedClientEmployees[client.client_id]?.manager_id || ""}
      type="select"
      dropdown={managersList}
      changeData={(e) => {
        chooseManager(e, client.client_id);
      }}
    />
    <Labelbox
      value={selectedClientEmployees[client.client_id]?.employee_id || ""}
      type="select"
      dropdown={employeesList}
      changeData={(e) => {
        chooseEmployee(e, client.client_id);
      }}
    />
  </div>
))}

              </div>
            </div>
            <center>
              <div className="hbox flex-center">
                <Button
                  ui="primary"
                  text="Approve"
                  handleClick={() => ActionClick("approve")}
                />
                <Button
                  ui="danger"
                  text="Reject"
                  handleClick={() => ActionClick("reject")}
                />
              </div>
            </center>
          </FrameBox>
        ) : (
          <Empty />
        )}
      </Modal>
    </div>
  );
};

export default Dashboard;
