import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Labelbox,
  Table,
  FrameBox,
  InfoTable,
  Button,
  Modal,
  ContractInvoiceView,
} from "../../components";
import { Grid, Paper, TableCell } from "@material-ui/core";
import "./payment.scss";
import { INVOICE_ACTION } from "../../redux/action";
import { dateFormatter } from "../../utils/common";
import { useLocation } from "react-router-dom";
import axios from "axios";

// Import the QR Code PDF
import ZelleQR from "../../images/Zelle-QR-image.png";
import { font } from "../../resources/fonts/fontBase64";

const ContractPayment = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [invoices, setInvoices] = React.useState([]);
  const [InvoiceList, setInvoiceList] = React.useState([]);
  const [paymentType, setPaymentType] = React.useState("");  // Track selected payment method
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [isModalOpen, setModalOpen] = React.useState({
    state: false,
    data: {},
  });
  const { userId } = props;

  const USER_DATA = userId
    ? props.USER_DATA
    : JSON.parse(localStorage.getItem("USER_DATA"));

    const [isButtonEnabled, setButtonEnabled] = React.useState(false); // State for enabling/disabling the button

  useMemo(() => {
    if (location.state?.data)
      setModalOpen({ state: true, data: location.state.data });
  }, [location]);

  useLayoutEffect(() => {
 let InvoiceList = dispatch(
 INVOICE_ACTION.getAllInvoices({
        billed_to: userId || localStorage.getItem("user_id"),
        status: 0,
      })
    );

  }, [userId, dispatch]);

  const {
    invoice: { allInvoices },
  } = useSelector((state) => state);

  useEffect(() => {
    const extractInvoiceNumber = (invoice_no) => parseInt(invoice_no.replace('INV-', ''));
    const validInvoices = allInvoices.filter((invoice) => Number(invoice.total) > 0);
    const sortedInvoices = [...validInvoices].sort((a, b) => extractInvoiceNumber(b.invoice_no) - extractInvoiceNumber(a.invoice_no));

    setInvoices(
      sortedInvoices.map((data) => {
        const { invoice_no, total, invoice_date, amount_paid } = data;
        return {
          select: false,
          invoice_no,
          invoice_date: dateFormatter(invoice_date),
          amount: `$ ${total}`,
          due_amount: `$ ${Math.max((total - amount_paid),0).toFixed(2)}`,
        };
      })
    );
  }, [allInvoices]);

  const onChange = (value, key, rowIndex) => {
    invoices[rowIndex][key] = value;
    setInvoices([...invoices]);
  };

  useEffect(() => {
    const isAnyInvoiceSelected = invoices.some((invoice) => invoice.select);
    setButtonEnabled(isAnyInvoiceSelected);
  }, [invoices]);

  const total = useMemo(() => {
    return invoices.reduce(
      (accumulator, currentValue) =>
        accumulator +
        (currentValue.select
          ? Number(currentValue.due_amount.replace(/[^0-9.]/g, ""))
          : 0),
      0
    );
  }, [invoices]);
  // Zelle Payment Handler - Open Modal
  const handleZellePayment = () => {
    setModalOpen(true);
    setPaymentType("zelle");
    setTotalAmount(total);  // Capture the total amount at the time of payment
  };

  const handleAuthorizeNetPayment = async () => {
    setPaymentType("authorize.net");
  
    // Define API Credentials & Transaction Request Data
    const apiLoginId = "5Yb6Hf9Mh"; // Replace with your actual API Login ID
    const transactionKey = "2XR342sHLPa2f4LD"; // Replace with your actual Transaction Key
    //const clientKey = "47gnmKGH6R5MeYf94PNTDVWj8Y5V8yCCZ23qRqUhtDPTGgsvz75AuTJ75LNep9n8"; // Replace with your actual Client Key
    const endpoint = "https://api2.authorize.net/xml/v1/request.api";
    // process.env.NODE_ENV === "production"
    //   ? "https://api2.authorize.net/xml/v1/request.api" // PRODUCTION
    //   : "https://apitest.authorize.net/xml/v1/request.api"; // SANDBOX
  
    // Create a payment request payload
   console.log("Invoices Selected inside authorize.net: ",invoices);
    const requestPayload = {
      getHostedPaymentPageRequest: {
        merchantAuthentication: {
          name: apiLoginId,
          transactionKey: transactionKey,
        },
        transactionRequest: {
          transactionType: "authCaptureTransaction",
          amount: total || 100, // Ensure total is correctly formatted
          currencyCode: "USD",
          lineItems: {
            lineItem: invoices
              .filter((invoice) => invoice.select)
              .map((invoice) => ({
                itemId: invoice.invoice_no,
                name: `Invoice ${invoice.invoice_no}`,
                description: `Payment for Invoice ${invoice.invoice_no}`,
                quantity: 1,
                unitPrice: Number(invoice.amount.replace(/[^0-9.]/g, "")),
              })),
          },
        },
        hostedPaymentSettings: {
          setting: [
            {
              settingName: "hostedPaymentReturnOptions",
              settingValue: JSON.stringify({
                showReceipt: true,
                url: "https://staging.finlotax.com/finlo/payment", // Update this if necessary
                urlText: "Return to Website",
                cancelUrl: "https://staging.finlotax.com/finlo/payment",
              }),
            },
            {
              settingName: "hostedPaymentButtonOptions",
              settingValue: JSON.stringify({
                text: "Pay Now",
              }),
            },
          ],
        },
      },
    };
    
    console.log("Authorize.Net Request Payload:", JSON.stringify(requestPayload, null, 2));

    try {
      const response = await axios.post(endpoint, requestPayload, {
        headers: { "Content-Type": "application/json" },
      });
    
      console.log("Authorize.Net Response:", response.data); // Debug API Response
      const token = response.data.token;
      console.log("Authorize.Net Token is :", token); // Debug API Response
      if (
        response.data.token
      ) {
        const token = response.data.token.trim();  // Remove any trailing spaces
        //const encodedToken = encodeURIComponent(token);  // Ensure URL encoding
        const baseUrl = "https://test.authorize.net/payment/payment"
        // process.env.NODE_ENV === "production"
        //   ? "https://accept.authorize.net/payment/payment"
        //   : "https://test.authorize.net/payment/payment";
       // window.location.href = `${baseUrl}?token=${encodedToken}`;
       setTimeout(() => {
        window.location.href = `${baseUrl}?token=${token}`;
      }, 500); // Redirect after 500ms
      
      } else {
        console.error("Authorize.Net Error:", response.data);
        alert(
          `Payment initiation failed: ${
            response.data?.messages?.message?.[0]?.text || "Unknown error"
          }`
        );
      }
    } catch (error) {
      console.error("Authorize.Net Payment Error:", error.response?.data || error);
      alert("Error connecting to payment gateway.");
    }
    
  };
  

  // SwipePay Handler - Redirect
  const handleSwipePay = () => {
    setPaymentType("swipepay");
    window.location.href = "https://www.swipepay.com/payment";  // Replace with actual SwipePay URL
  };
  const onActionClick = (action, row, actionIndex) => {
    setModalOpen({ state: true, data: allInvoices[actionIndex] });
  };

  const fields = [
    {
      type: "checkbox",
      changeData: onChange,
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
    {
      type: "label",
    },
  ];
  return (
    <FrameBox title="Payment / Invoice">
      <Grid container spacing={3} className="paddingTop">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FrameBox title="Tax Associate Info" variant="small">
            <Paper>
              <InfoTable
                titles={["Name", "Email ID", "Phone"]}
                info={{
                  name: USER_DATA.employee || "-",
                  email: USER_DATA.employee_email || "-",
                  phone: USER_DATA.employee_phone || "",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="support-info">
          <FrameBox title="Support Info" variant="small">
            <Paper>
              <InfoTable
                titles={["Email ID", "Phone"]}
                info={{
                  email: "help@finlotax.com",
                  contact: "(408) 822-9406",
                }}
              />
            </Paper>
          </FrameBox>
        </Grid>
        <Grid item xs={12}>
          <Table
            variant="small"
            title="Select the Invoice / Invoices you want to pay"
            header={[
              // { id: "1", label: "" },
              { id: "1", label: "" },
              { id: "2", label: "Description / Invoice Ref. #" },
              { id: "3", label: "Invoice Date" },
              { id: "4", label: "Net Amount" },
              { id: "5", label: "Due Amount" },
            ]}
            data={invoices}
            fields={fields}
            // icons={{ eyeIcon: true }}
            onActionClick={onActionClick}
            customRow={
              <>
                <TableCell></TableCell>
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>$ {total.toFixed(2)}</TableCell>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Labelbox
            type="checkbox"
            label={
            
              <span className="terms">
                I have read the{" "}
                <span>
                <a href="https://finlotax.com/privacy-policy/" target="_blank"  style={{textAlign:'center'}}>
                Terms & Conditions, Privacy Policy, Disclaimer
             </a>
              </span> and
                proceed to pay via following payment methods
              </span>
            }
            checked
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className="payment-notes">
            <span className="payment-notes-msg">Notes:</span>
            <ul>
              <li>
              Please ensure that you are sending the payment to the correct recipient - accounts@finlotax.com
              </li>
              <li>Transactions made via Zelle are instant and cannot be reversed</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} className="flex flex-center">
          
        <Button
        disable={!isButtonEnabled} // Ensure button is disabled when no invoice is selected
            text={
              <div>
                <img
                  src={require("../../images/zelle-logo.png")}
                  alt="Zelle"
                  style={{ width: "30px", marginRight: "10px" }}
                />
                Pay with Zelle
              </div>
            }
            large
            handleClick={handleZellePayment}
          />
          {/* <Button
         disable={!isButtonEnabled} // Ensure button is disabled when no invoice is selected
            text={
              <div>
                <img
                  src={require("../../images/authorize.jpeg")}
                  alt="authorize.net"
                  style={{ width: "30px", marginRight: "10px" }}
                />
                Pay with authorize.net
              </div>
            }
            large
            handleClick={handleAuthorizeNetPayment}
          /> */}
          {/* <Button text="SwipePay" large handleClick={handleSwipePay}/> */}
        </Grid>
      </Grid>
      {isModalOpen.state && (
        <Modal
          open={isModalOpen.state}
          title="Invoice Preview"
          maxWidth
          handleClose={() => setModalOpen({ state: false, data: {} })}
        >
          <ContractInvoiceView data={isModalOpen.data} label="Invoice" />
        </Modal>
      )}
       {/* Zelle QR Code Modal */}
       {isModalOpen && paymentType === "zelle" && (
       <Modal
       open={isModalOpen}
       title="Scan QR Code to Pay with Zelle"
       handleClose={() => setModalOpen(false)}
       maxWidth
     >
       <div className="qr-container" style={{ fontSize: "18px", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
         {/* <p style={{ margin: "10px 0" }}>Company Name: <strong>FinloTax Inc</strong></p>
         <p style={{ margin: "10px 0" }}>Zelle Id: <strong>accounts@finlotax.com</strong></p> */}
         <p style={{ margin: "10px 0" }}>Total Amount to be paid: <strong>${totalAmount.toFixed(2)}</strong></p>
       </div>
       <Grid item xs={12}>
          <Paper className="payment-notes">
            <ul>
              <li>
              Scan the barcode using your banking app or Zelle app to send your payment securely.
              </li>
              <li>Ensure the correct amount is entered before confirming the transaction.</li>
            </ul>
          </Paper>
        </Grid>
       <div className="qr-container" style={{ fontSize: "18px", textAlign: "center", display: "flex", justifyContent: "center" }}>
         <iframe
           src={ZelleQR}
           width="500px"
           height="440px"
           title="Zelle QR Code"
         />
       </div>
     </Modal>
      )}
    </FrameBox>
  );
};

export default ContractPayment;
