import React from "react";
import { Folder, File, ExcelIcon } from "../../images"; // Import specific icons
import PdfIcon from "../../images/PdfIcon.webp";
import WordIcon from "../../images/WordIcon.webp";
import ImageIcon from "../../images/ImageIcon.webp";
import "./file-folder.scss";
import { Visibility, GetAppRounded, Delete } from "@material-ui/icons";

const getFileIcon = (extension) => {
  const extensionMap = {
    "pdf": PdfIcon,
    "doc": WordIcon,
    "docx": WordIcon,
    "xls": ExcelIcon,
    "xlsx": ExcelIcon,
    "png": ImageIcon,
    "jpg": ImageIcon,
    "jpeg": ImageIcon,
    "svg": ImageIcon,
  };
  return extensionMap[extension.toLowerCase()] || File;
};

const FileFolderCard = (props) => {
  const {
    name,
    onMenuClick,
    onClick,
    details,
    modifiedAt,
    createdAt,
    document_type,
    document_extension,
    type,
    size,
    id,
  } = props;

  return (
    <div className="folder-table">
      <div className="card" onClick={() => onClick && onClick(name, id)}>
        <div className="folder-cell" id="doc_name">
        <div style={{ display: "inline-flex" }}>
            <span className="folder_icon">
              <img
                src={type === "Document" ? getFileIcon(document_extension) : Folder}
                alt="file icon"
              />
            </span>
            <div>{name}</div>
          </div>
        </div>
        <div className="folder-cell" id="doc_date">
          {createdAt || "---"}
        </div>
        <div className="folder-cell" id="doc_date">
          {modifiedAt || "---"}
        </div>

        <div className="folder-cell" id="doc_type">
          {type === "Document" ? document_extension : type || "---"}
        </div>
        <div className="folder-cell" id="doc_size">
          {type === "Document" ? size : ""}
        </div>
        <div className="folder-cell">
          <div className="menu_icon">
            {/* {type === "Document" &&
              new RegExp(["image", "application/pdf"].join("|")).test(
                document_type
              ) && (
                <div
                  className="flex flex-center" onClick={() => {
                    new RegExp(["image", "application/pdf"].join("|")).test(
                      document_type
                    ) && onMenuClick && onMenuClick("Preview", details);
                  }}
                  // onClick={() =>
                  //   onMenuClick ? onMenuClick("Preview", details) : {}
                  // }
                >
                  <Visibility />
                </div>
              )} */}
            <div
              className="flex flex-center"
              onClick={(e) => {
                e.stopPropagation();
                onMenuClick && onMenuClick("Download", details);
              }}
            >
              <GetAppRounded />
            </div>
            {/* {type === "Document" &&
              new RegExp(["image", "application/pdf",  "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].join("|")).test(
                document_type
              ) && localStorage.getItem("user_role") !=="client" && (
            <div className="flex flex-center" style={{ color: '#e41f3a' }} onClick={(e) => {
             
           onMenuClick("Delete", details); }}>
                    <Delete />
                    </div> )} */}
          </div>
          {/* <Menus
            options={options}
            onMenuClick={(item) =>
              onMenuClick ? onMenuClick(item, details) : {}
            }
          /> */}
        </div>
      </div>
    </div>
  );
};

export default FileFolderCard;
